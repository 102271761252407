.div2{
    display:flex;
    justify-content:center;
    align-items:center;
    position:fixed;
    bottom:0px;
    height:60px;
    width:100vw;
    background-color:#ffffff22;
    color:#fff;
    z-index: 1;
}
@media (max-Width:1000px){
    .div2{
        display:flex;
        justify-content:center;
        align-items:center;
        position:fixed;
        bottom:0px;
        height:60px;
        width:100vw;
        background-color:#ffffff22;
        color:#fff;
        font-size: 12px;
     
    }
    .footer-container{
        margin-top: 50%;

    }
    
}