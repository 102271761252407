.div1{
    display:flex;
    justify-content:start;
    align-items:center;
    position:fixed;
    top:0px;
    height:65px;
    width:100vw;
    background-color:#ffffff22;
    z-index: 1;
}

.logo{
    height:60px;
    width:160px;
    margin-Left:60px;
    
}
@media (max-Width:1000px){
    .div1{
        display:flex;
        justify-content:start;
        align-items:center;
        position:fixed;
        top:0px;
        height:65px;
        width:100vw;
        background-color:#ffffff22;
        z-index: 1;
    }
    
    .logo{
        height:40px;
        width:75px;
        margin-Left:30px;
        
    }
    
}
