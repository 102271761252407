@media (max-width:1000px){
    .main{
      display: grid;
      width:100vw;
      height: 100vh;
    }
    .contact{
      width: 100vw;
      height:25vh;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 100px;
  
     
    }
   .bannerimg{
    width: 100%;
    margin-top:0px;
   } 
   .imgmain{
    width:97vw;
    height:75vh;
    display: flex;
    justify-content: center;
    align-items: center;
   }
   
   .title{
    font-size:1.1375rem;
    text-align: center;
    margin-bottom:.3rem;
   }
   .sub-title{
    font-size:0.7375rem;
    text-align: center;
   }
    
  }
  